<template lang="pug">
.contacts(:class="size")
  .contact(@click="sendMetrica(YmGoals.ContactsClick)")
    ui-icon(:icon="UiIconNames.Icon_Support" :size="iconSize")
    a(:href="FreePhoneNumber.link") {{ FreePhoneNumber.value }} бесплатно по РФ
  .contact._green(@click="sendMetrica(YmGoals.ContactsClick)")
    ui-icon(:icon="UiIconNames.Landing_ChatPhone" :size="iconSize")
    a(:href="WhatsAppNumber.link" target="_blank") {{ WhatsAppNumber.value }} WhatsApp
  .contact(@click="sendMetrica(YmGoals.ContactsClick)")
    ui-icon(:icon="UiIconNames.Icon_Support" :size="iconSize")
    a(:href="PhoneNumber.link") {{ PhoneNumber.value }}
  .contact(@click="sendMetrica(YmGoals.ContactsClick)")
    ui-icon(:icon="UiIconNames.Landing_Chat" :size="iconSize")
    a(:href="getMailLink()") {{ MailAddress.value }}
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { sendMetrica, YmGoals } from "~/utils/logger/metrica";
import { PhoneNumber, FreePhoneNumber, MailAddress, WhatsAppNumber } from "@/const/contacts";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "SupportContacts",
  components: {
    UiIcon,
  },
  props: {
    mode: {
      type: String as PropType<'403'|'404'|'tariff'|'map'|'footer'>,
      default: 'map',
    },
    size: {
      type: String as PropType<'small'|'medium'|'large'>,
      default: 'medium',
    },
  },
  setup(props, context) {

    const iconSize = props.size === 'small' ? 12 : props.size === 'medium' ? 16 : 24

    function getMailLink() {
      switch (props.mode) {
        case '403':
          return MailAddress.link403;
        case '404':
          return MailAddress.link404;
        default:
          return MailAddress.subjectLink;
      }
    }

    return {
      iconSize,
      UiIconNames,
      PhoneNumber,
      WhatsAppNumber,
      FreePhoneNumber,
      MailAddress,
      YmGoals,
      getMailLink,
      sendMetrica,
    }
  }
})
</script>

<style scoped lang="scss">
.contacts {
  display: flex;
  flex-flow: column;
  gap: 8px;
  font-size: 14px;

  &.small {
    gap: 8px;

    font-size: 12px;
    line-height: 14px;
  }

  &.medium {
    gap: 8px;

    font-size: 14px;
    font-weight: 500;
  }

  &.large {
    gap: 16px;

    font-size: 18px;
    font-weight: 500;
  }
}

.contact {
  display: flex;
  flex-flow: row;
  gap: 8px;
  align-items: center;

  a:hover:not(._green) {
    cursor: pointer;
    color: var(--main-color-blue);
  }

  &._green {
    svg {
      color: #25d366;
    }

    a:hover {
      color: #25d366;
    }
  }

  svg {
    color: var(--main-color-blue);
  }
}

@media (max-width: 1000px) {
  .contacts:not(.small) {
    font-size: 14px;
  }
}
</style>
